html {
    min-height: 100vh;
}

body {
    box-sizing: border-box;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}